<template>
  <div id="the-signature-completed-or-rejected">
    <div v-if="!rejected" id="completed">
      <VasionSignatureIconBig />
      <div id="message" class="vasion-page-title-black">
        {{ confirmationMessage }}
      </div>
      <div id="sub-message" class="vasion-page-subheader">
        {{ confirmationSubMessage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheSignatureConfirmation',
  props: {
    confirmationMessage: {
      type: String,
      required: true,
    },
    confirmationSubMessage: {
      type: String,
      required: true,
    },
  },
  async created() {
    localStorage.setItem('UserToken', '')
    localStorage.setItem('Username', '')
    localStorage.setItem('LoginType', 1)
    localStorage.setItem('UserID', 0)
    localStorage.setItem('Roles', [])
    localStorage.setItem('AppAccess', null)
    localStorage.setItem('IsAdmin', false)
    localStorage.setItem('FirstName', '')
    localStorage.setItem('UserHasForms', false)
    localStorage.setItem('UserHasSchedulers', false)
    localStorage.setItem('IsSignatureDocLinkView', false)
    localStorage.setItem('UserHasWorkflowsToEdit', false)
    await this.$store.dispatch('common/setIsSignatureDocLinkView', false)
    await this.$store.dispatch('common/setIsSignedIn', false)
    await this.$store.dispatch('systemSettings/resetStoreData')
    await this.$store.dispatch('common/setUserData', {
      AppAccess: null,
      Token: '',
      Username: '',
      LoginType: 1,
      UserId: 0,
      Roles: [],
      IsAdmin: false,
      UpdateStorage: true,
      FirstName: '',
      UserHasForms: false,
      UserHasSchedulers: false,
      UserHasWorkflowsToEdit: false,
    });
    await this.$store.dispatch('common/signOut')
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #the-signature-completed-or-rejected {
    text-align: center;
    background-color: $grey-50;
    height: calc(100vh - 50px);
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #message {
    margin-top: 35px;
    margin-bottom: 24px;
    max-width: 765px;
  }

  #sub-message {
    margin-top: 24px;
    max-width: 720px;
  }
 .check {
    width: 100px;
  }

</style>
